<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">오키나와 지역의 매력적인 관광지와 인기 시설을 대상으로 한 가성비 있는 티켓입니다. 유효 기간 내에 대상 시설 중에서 원하는 시설을 3곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR 코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간</span> <br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>3개까지 </span> 이용 가능<br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokinawa/kr/havefun_title_kr.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfokinawa/en/oguri_kimono_salon_EN.jpg'),
          title: ['오구리 기모노 살롱 기모노 체험 1000엔 쿠폰 (성인 한정)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나하시 마쓰오 2-3-13 2층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['http://oguri-kimono.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. (고등학생 이상만 이용 가능합니다.) '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다.'] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfokinawa/en/chibai_EN.jpg'),
          title: ['야키니쿠 호르몬 아카시로 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나하시 구메 2-20-21 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yakinikuhorumon_akashiro/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다.'] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 3, text: ['요금 차액이 발생할 경우, 현장에서 추가 지불하셔야 합니다.'] },
                { cid: 4, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokinawa/en/SZG_EN.jpg'),
          title: ['오키나와 츄라우미 수족관 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 구니가미군 모토부초 야마카와 1421-5 (우미카지 마켓 모토부점)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://churaumi.okinawa/kr/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※ 태풍 등 불가항력적인 사정으로 인해 대중교통의 운행이 중지될 경우에는 영업을 중단할 수도 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokinawa/en/LQC_EN.jpg'),
          title: ['류큐무라(琉球村) 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 구니가미군 온나손 야마다 1130'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.ryukyumura.co.jp/ko/official/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※ 태풍 등 불가항력적인 사정으로 인해 대중교통의 운행이 중지될 경우에는 영업을 중단할 수도 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokinawa/en/YQD_EN.jpg'),
          title: ['오키나와 월드 입장권 프리패스 (교쿠센도, 왕국촌, 반시뱀 박물공원)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 난조시 다마구스쿠 마에카와 1336'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.gyokusendo.co.jp.k.aqs.hp.transer.com/okinawaworld/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※ 태풍 등 불가항력적인 사정으로 인해 대중교통의 운행이 중지될 경우에는 영업을 중단할 수도 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokinawa/en/zwy_EN.jpg'),
          title: ['동남식물낙원 입장권[주간]'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 오키나와시 치바나 2146'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.southeast-botanical.jp/ko/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※ 태풍 등 불가항력적인 사정으로 인해 대중교통의 운행이 중지될 경우에는 영업을 중단할 수도 있습니다.'] },
                { cid: 2, text: ['입장가능시간 : 09:00~17:00','<br>※야간 입장은 현장에서 별도입장권을 구매해 주세요. '] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokinawa/en/kl_EN.jpg'),
          title: ['DINO 공룡 파크 얀바루 아열대의 숲 입장권'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나고시 나카야마 1024-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.okashigoten.co.jp/ko/subtropical/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※ 태풍 등 불가항력적인 사정으로 인해 대중교통의 운행이 중지될 경우에는 영업을 중단할 수도 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokinawa/en/bl_EN.jpg'),
          title: ['나고 파인애플 파크 입장권 (파인애플 트레인 포함)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나고시 비이마타 1195'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.nagopine.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※ 태풍 등 불가항력적인 사정으로 인해 대중교통의 운행이 중지될 경우에는 영업을 중단할 수도 있습니다.'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokinawa/en/Neopark_EN.jpg'),
          title: ['네오파크 오키나와 입장권+오키나와 경편 철도 승차권 세트'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나고시 나고 4607-41'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.neopark.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. ','<br>※가이드 기관차 운행 시간 : 오전 10시~오후 5시 (30분마다 운행, 마지막 편은 오후 5시)'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfokinawa/en/sllr_EN.jpg'),
          title: ['슈리류센 산호 염색 체험 1000엔 쿠폰 (예약 필수)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나하시 슈리야마가와초 1-54'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.shuri-ryusen.com/']},
                { cid: 2, text: ['예약 페이지:'],noColor:true},
                { cid: 3, text: ['ahttps://reserve.shuri-ryusen.com/step1']},
                { cid: 4, text: ['<span style="color:red">※ 예약하실 때 지불 방법으로 ""店頭でのお支払い(매장에서 지불)""을 선택해 주세요.</span>'] ,noColor:true },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['완전 예약제로 운영하고 있습니다. 이용하실 때에는 사전에 예약해 주세요. 사전 예약을 하지 않으셨을 경우, 휴업일인 경우도 있습니다. 확인하신 후에 이용해 주세요. '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 3, text: ['체험 시간 : 9:30, 11:00, 12:30, 14:00, 15:30','※ 체험 시간은 약 50분 정도 소요되므로, 체험 시간 15분 전까지 방문해 주세요.'] },
                { cid: 4, text: ['정원 마감으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfokinawa/en/haidi_EN.jpg'),
          title: ['수중관광선 ORCA호 해저 산책 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나하시 돈도초 2-1 (나하 부두 대기소)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://ko.westmarine.co.jp/top.php ']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 2, text: ['체험 시간은 약 50~60분 정도 소요됩니다.'] },
                { cid: 3, text: ['당일 승객이 많을 경우, 안전상의 이유로 전망실 입장 시 인원 제한을 두고 교대로 안내할 수 있습니다.'] },
                { cid: 4, text: ['정기적으로 유람선 점검을 실시합니다. 점검 기간 중에는 운행을 중단합니다. 또한 날씨 및 바다 상황에 따라 (예: 태풍이나 파도로 인해 배가 안정되지 않거나 바다의 시야가 좋지 않은 날 등) 이용 당일 배를 타는 것이 적절하지 않다고 판단될 경우, 취소 또는 일정 변경을 선택할 수 있습니다.'] },
                { cid: 5, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다. '] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfokinawa/en/yuyi_EN.jpg'),
          title: ['츄라 사쿠라 기모노/유카타 체험 1000엔 쿠폰 (이용 가능 시간 11:00)'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나하시 구모지 1-6-1 ART빌딩 2-A'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.churasakura.okinawa/#']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요.'] },
                { cid: 2, text: ['이용 가능 시간 11:00'] },
                { cid: 3, text: ['방문하신 시간대에 고객님이 많은 경우, 대기 시간이 있을 수 있으므로 양해 부탁드립니다.'] },
                { cid: 4, text: ['이용 당일 18:00까지 반납해 주세요. 반납 시간 이후 반납할 경우, 자동적으로 다음 날 반납하셔야 하며 1,100엔의 추가 요금이 발생합니다. 또한, 대여 당일 맡겨두신 소지품은 찾을 수 없는 점 양해 부탁드립니다.'] },
                { cid: 5, text: ['반납 후 대여하신 물품에 복구 불가능한 오염(페인트 등), 파손(담배 자국, 봉제 부분 이외의 찢어짐, 구멍 뚫림 등)은 손해배상금으로 5,000엔이 청구되므로, 현지에서 지불하셔야 합니다.'] },
                { cid: 6, text: ['이용 당일에는 갈아입기 편한 이너웨어 또는 타이트한 복장을 추천드립니다.'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfokinawa/en/heniu_EN.jpg'),
          title: ['오키나와 규사무라이 1000엔 쿠폰'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '오키나와현 나카가미군 차탄초 미하마 34-3 DEPOT CENTRAL 6층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.gyu-samurai.com/ ']},
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000엔 쿠폰을 사용하실 수 있습니다. (아래 세트 메뉴 주문 시에만 쿠폰 사용 가능) ',
                '<br>・A세트 (계절에 따라 변동될 수 있음) <br>전채 2종, 모둠 김치, 상등 우설, 특선 상등 소 등심, 특선 상등 소갈비, 오키나와산 아구 흑돼지 갈비, 특선 설로인 스테이크, 내장 3종, 다시마 계란국, 밥, 디저트, 무알코올 드링크 1잔 ',
                '<br>・B세트 (계절에 따라 변동될 수 있음) <br>전채 2종, 오늘의 샐러드, 모둠 김치, 상등 우설, 극상 소 등심, 극상 소갈비, 특선 소 안심, 오키나와산 아구 흑돼지 갈비, 해산물 3종, 내장 3종, 다시마 계란국, 밥, 디저트, 무알코올 드링크 1잔 '] },
                { cid: 2, text: ['영업시간은 사전에 공식 홈페이지를 확인해 주세요. '] },
                { cid: 3, text: ['만석으로 인해 이용하지 못할 수도 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkinawa&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okinawa-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>